import React from "react"
import { Link } from "gatsby"
import Common from "../components/common"
import SEO from "../components/seo"
import { Form, Text, TextArea, Select, Option } from "informed"
import Footer from "../components/footer"
import "./contact.less"
import produceplanlogo from "../images/produceplanlogo.svg"
import tractorImage from "../images/tractor.png"
import { ddp } from "../api/ddp"

const notEmpty = value => {
  return !value ? "Required Field" : undefined
}

class ContactPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      commodityTypes: [],
      submitting: false,
      submitted: false,
    }
  }
  componentDidMount() {
    const methodId = ddp.method("public/get/commodityTypeNames");
    ddp.on("result", message => {
      if (message.id === methodId && !message.error) {
        this.setState({
          commodityTypes: message.result,
        })
      }
    })
  }
  handleSubmit(doc) {
    const methodId = ddp.method("contactUs", [{ ...doc, contactOwners: true }]);
    this.setState({ submitting: true });
    ddp.on("result", message => {
      if (message.id === methodId) {
        this.setState({ submitting: false });
        if (message.error) {
          this.setState({
            error: true,
          })
        } else {
          this.setState({
            submitted: true,
          })
        }
      }
    })
  }
  render() {
    const { submitting, submitted, error, commodityTypes } = this.state
    return (
      <>
        <Common />
        <SEO title="Contact Us" />
        <div id="contact-us-wrapper">
          <div id="contact-us-left">
            <div id="contact-us-header">
              <div id="contact-us-header-left">
                <Link to="/">
                  <img
                    src={produceplanlogo}
                    alt="Produceplan™"
                    id="contact-logo"
                  />
                </Link>
              </div>
              <div id="contact-us-header-right">We can help.</div>
            </div>
            <h1>General Inquiries?</h1>
            <p>
              Get in touch with our regional specialists on genetics,
              technology, program packages and other information that you may
              need.
            </p>
            <p>
              <strong>
                Complete this form and the appropriate team member will reach
                out as soon as possible...
              </strong>
            </p>
            <h2>Current customers:</h2>
            <p>
              <span className="contact-orange">Fulfillment and Billing:</span>{" "}
              <br />
              +1-877-450-0047
            </p>
            <p>
              <span className="contact-orange">Technology Support:</span> <br />
              support@sostena.com
            </p>
            <img src={tractorImage} style={{ maxWidth: "100%" }} />
          </div>
          <div id="contact-us-right">
            {submitted && (
              <div>
                <h1>Thanks!</h1>
                <p>We will be in touch.</p>
              </div>
            )}{" "}
            {!submitted && (
              <>
                <h1>Who are ya?</h1>

                <Form onSubmit={doc => this.handleSubmit(doc)}>
                  {({ formState }) => (
                    <>
                      <p className="{{#if invalidForm}}invalid{{/if}}">
                        Please complete all fields.
                      </p>
                      <div className="row">
                        <div style={{ color: "red" }}>
                          {formState.errors.full_name}
                        </div>
                        <Text
                          field="full_name"
                          placeholder="Your Name"
                          validate={notEmpty}
                        />
                      </div>

                      <div className="row">
                        <div style={{ color: "red" }}>
                          {formState.errors.job_title}
                        </div>
                        <Text
                          field="job_title"
                          placeholder="Job Title"
                          validate={notEmpty}
                        />
                      </div>
                      <div className="row">
                        <div style={{ color: "red" }}>
                          {formState.errors.email}
                        </div>
                        <Text
                          field="email"
                          placeholder="Your Email"
                          validate={notEmpty}
                        />
                      </div>
                      <div className="row">
                        <div style={{ color: "red" }}>
                          {formState.errors.phone_number}
                        </div>
                        <Text
                          field="phone_number"
                          placeholder="Phone Number"
                          validate={notEmpty}
                        />
                      </div>
                      <div className="row">
                        <div style={{ color: "red" }}>
                          {formState.errors.company_name}
                        </div>
                        <Text
                          field="company_name"
                          placeholder="Your Company"
                          validate={notEmpty}
                        />
                      </div>
                      <div className="row">
                        <div style={{ color: "red" }}>
                          {formState.errors.number_of_employees}
                        </div>
                        <Select field="number_of_employees" validate={notEmpty}>
                          <Option selected disabled value="">
                            Number of Employees
                          </Option>
                          <Option value="1-5">1-5</Option>
                          <Option value="5-10">5-10</Option>
                          <Option value="10-50">10-50</Option>
                          <Option value="50-100">50-100</Option>
                          <Option value="100+">100+</Option>
                        </Select>
                      </div>

                      <div className="row">
                        <div style={{ color: "red" }}>
                          {formState.errors.region}
                        </div>
                        <Select field="region" validate={notEmpty}>
                          <Option selected disabled value="">
                            Service Region
                          </Option>
                          <Option value="USA EAST">USA East</Option>
                          <Option value="USA WEST">USA West</Option>
                          <Option value="USA CENTRAL">USA Central</Option>
                          <Option value="MEXICO">Mexico</Option>
                          <Option value="OTHER">Other</Option>
                        </Select>
                      </div>

                      <div className="row">
                        <Select field="product_interest" validate={notEmpty}>
                          <div style={{ color: "red" }}>
                            {formState.errors.product_interest}
                          </div>
                          <Option selected disabled value="">
                            Product Interest
                          </Option>
                          <Option value="Planting Schedules">
                            Planting Schedules
                          </Option>
                          <Option value="Sales Schedules">
                            Sales Schedules
                          </Option>
                          <Option value="Suppliers Tools">
                            Suppliers Tools
                          </Option>
                          <Option value="Input Quotes">Input Quotes</Option>
                          <Option value="GroundSight">GroundSight</Option>
                          {commodityTypes.map(type => (
                            <Option value={type}>{type}</Option>
                          ))}
                        </Select>
                      </div>

                      <div className="row">
                        <div style={{ color: "red" }}>
                          {formState.errors.comments}
                        </div>
                        <TextArea
                          validate={notEmpty}
                          placeholder="Add Comments"
                          field="comments"
                        ></TextArea>
                      </div>

                      <div className="row center-align">
                        <button type="submit" disabled={submitting}>
                          {submitting && "Submitting"}Get In Touch!
                        </button>
                      </div>
                    </>
                  )}
                </Form>
              </>
            )}
          </div>
          <div id="contact-us-footer">
            <p className="disclaimer">
              Disclaimer:
              <br />
              In our Products, we use information models, research and data
              (including historical, estimated and simulated weather and
              agronomic data), and combine it with information provided by you,
              to generate the suggestions, moisture profile percentages, growth
              stages, anticipated transplant dates and other information we
              provide. The generated information and suggestions are estimates
              based on these inputs, and we cannot guarantee actual results. Our
              Products, models, data and suggestions may change over time.
              Individual results may vary, as weather, growing conditions and
              farming practices differ across growers, locations and years.
              <br />
              Our suggestions and the Products should not be used as a
              substitute for sound farming practices, including diligent field
              monitoring, or as a sole means for making farming, risk management
              or financial decisions. We want to help you make better decisions,
              but we are not acting as your agronomist, pest control advisor,
              financial advisor, insurance agent, commodity broker or agent.
              Consult those service professionals before making decisions.
              <br />
            </p>
            {/* <p className="copyright">
              &copy;{{ year }} Produceplan™ All Rights Reserved.
            </p> */}
            <p className="links">
              <a href="#">Terms of Use</a> |{" "}
              <a href="#">End User License Agreeement</a> |{" "}
              <a href="#">Privacy Policy</a>
            </p>
            <p className="contact">
              Contact us at +1-877-450-0047 or support@sostena.com
            </p>
          </div>
        </div>
        <Footer />
      </>
    )
  }
}
export default ContactPage
